import { Catalogs } from './catalogs';
import { CatalogItem } from './catalog-item';
import { AssignedAccount } from './assigned-account';

const STATUS = Catalogs.getCatalogs().USER_STATUS;

export class User {

  constructor(data) {

    this.userId = data.d0;

    this.statusCode = data.d2 && data.d2.d1 !== STATUS.UNCONFIRMED ?
                                 data.d2.d1 === STATUS.ENABLE : undefined;

    this.role = data.d4 ? new CatalogItem(data.d4) : undefined;
    this.names = data.d5 || '';
    this.firstSurname = data.d6 || '';
    this.secondSurname = data.d7 || '';
    this.position = data.d8;
    this.email = data.d12 || '';
    this.confirmEmail = data.d12;
    this.lang = data.d13 && data.d13.d1;

    this.grant = data.d18 && data.d18.length ?
                 data.d18.map(item => new CatalogItem(item)) : undefined;

    this.roleCode = data.d4 && data.d4.d1;

    this.accountId = data.d15;
    this.buyerId = data.d16;
    this.permission = data.d18 && data.d18.d1;
    this.accounts = {
      payment: data.d19 && data.d19.d1 && data.d19.d1.length
        ? data.d19.d1.map(account => new AssignedAccount(account))
        : null,
      information: data.d19 && data.d19.d2 && data.d19.d2.length
        ? data.d19.d2.map(account => new AssignedAccount(account))
        : null,
      matches: data.d19 && data.d19.d3 && data.d19.d3.length
        ? data.d19.d3.map(account => new AssignedAccount(account))
        : null,
      visit: data.d19 && data.d19.d4 && data.d19.d4.length
        ? data.d19.d4.map(account => new AssignedAccount(account))
        : null,
      disclaimer: data.d19 && data.d19.d5 && data.d19.d5.length
        ? data.d19.d5.map(account => new AssignedAccount(account))
        : null
    };
  }
}
<template src="./index.html"/>
<style src="./index.styl" lang="stylus" scoped />


<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    hasCancelButton: {
      type: Boolean,
      default: true
    },
    hasCancelIcon: {
      type: Boolean,
      default: true
    },
    confirmButtonText: {
      type: String,
      default: ''
    },
    caption: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    handleConfirm: {
      type: Function,
      default: () => null
    }
  },
  methods: {
    close () {
      this.$emit('update:is-open', false);
    }
  }
};
</script>
<template src="./index.html"/>
<style
  src="./index.styl"
  lang="styl"
  scoped>
</style>

<script>
  export default {};
</script>

import { CatalogItem } from './catalog-item';
export class Account {

  constructor(data) {

    this.hasBeenCertified = data.d6;
    this.certificationId = data.d2;
    this.statusCode = data.d1 && data.d1.d1;
    this.planType = data.d4 && data.d4.d1;
    this.certificationType = data.d3 && data.d3.d1;
    this.alternativeFlow = data.d7 && new CatalogItem(data.d7);
    this.isChangePlan = data.d15 || false;
    this.stageProcess = data.d16;
    this.matchesStageProcess = data.d17;
    this.articlesSize = data.d19;
    this.isAssociated = data.d20;
    this.isRenewal = data.d22;
    this.origin = data.d23 && new CatalogItem(data.d23);
  }
}
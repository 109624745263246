import { CatalogItem } from './catalog-item';

export class AssignedAccount {

  constructor(data) {

    this.id = data.d1;
    this.businessName = data.d2;
    this.rfc = data.d3;
    this.tin = data.d4;
    this.originCode = data.d5 && data.d5.d1;
    this.legalEntity = data.d6 && new CatalogItem(data.d6);
    this.certificationType = data.d7 && data.d7.d1;
    this.creationDate = data.d8;
    this.isEnabled = false;
  }
}
export class Catalogs {

  static #instance;

  catalogs;

  constructor() {

    this.catalogs = Object
      .freeze({
        TRACE_ID_HEADER: 'X-Trace-Id',
        MISSION_COMPLETE_HEADER: 'X-Mission-Complete',
        ORIGIN_TYPE: {
          LOCAL:   'LOCAL',
          FOREIGN: 'FOREIGN'
        },
        LEGAL_ENTITY_TYPE: {
          LEGAL:   'LEGAL',
          NATURAL: 'NATURAL'
        },
        CERTIFICATION_TYPE: {
          STANDARD: 'STD',
          ADVANCED: 'ADV'
        },
        CERTIFICATION_STATUS: {
          CERTIFIED: 'CERTIFIED',
          EXPIRED: 'EXPIRED'
        },
        LOCAL_COUNTRY_CODE: 'MX',
        LOCAL_PHONE_COUNTRY_CODE: '52',
        SECTIONS: {
          COMPANY_INFO:             'generalInformation',
          CONTACT_PERSON:           'contactPerson',
          PRODUCTS_AND_SERVICES:    'productsAndServices',
          MANAGEMENT_TEAM:          'teamManagement',
          FINANCIAL_INFO:           'financialInformation',
          MANAGEMENT_SYSTEM:        'systemManagement',
          INSURANCE_POLICIES:       'insurancePolicy',
          ETHICS_AND_COMPLIANCE:    'ethicsAndCompliance'
        },
        ALTERNATIVE_FLOW: {
          UPDATE: 'UPDATE',
          UPGRADE: 'UPGRADE',
          CHANGE_PLAN: 'CHANGE_PLAN',
          UPGRADING: 'UPGRADING',
          UPDATING: 'UPDATING',
          RENEWAL: 'RENEWAL',
          ACTIVE: 'ACTIVE',
          INACTIVE: 'INACTIVE'
        },
        USER_STATUS: {
          UNCONFIRMED:'UNCONFIRMED',
          ENABLE:'ENABLE',
          DISABLE:'DISABLE'
        },
        BUYER_STATUS: {
          ENABLED: 'ENABLE',
          DISABLED: 'DISABLE'
        },
        LANG: {
          SPANISH: {
            DESCRIPTION: 'Español',
            CODE: 'ES'
          },
          ENGLISH: {
            DESCRIPTION: 'Ingles',
            CODE: 'EN'
          }
        },
        ADVANCED_PROPOSAL: __ADVANCED_PROPOSAL_URL__,
        STANDARD_PROPOSAL: __STANDARD_PROPOSAL_URL__,
      });
  }

  static getCatalogs() {

    if (!this.#instance) {
      this.#instance = new Catalogs();
    }

    return this.#instance.catalogs;
  }
}

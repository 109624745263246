<template>
  <prv-page-loader v-if="isLoaded"/>
  <router-view v-else/>
</template>

<script>

  import PrvPageLoader from './components/prv-page-loader/prv-page-loader.vue';
  // import { TokenService } from './service/token-service';

  export default {
    components: {
      'prv-page-loader': PrvPageLoader
    },
    data() {
      return {
        isLoaded: true,
        // currentPeticion: 0,
        // inactivyTimer: null, // timeout
      };
    },
    created() {
      // localStorage.setItem('NECC', 0); // Counter
      // localStorage.setItem('NECT', 0); // Times

      // this.inactivyTimer = setTimeout( this.extendSesion , 5 * 60 * 1000); // 5 Minutos

      window.addEventListener('load', this.removePreloader);
    },
    destroyed() {
      window.removeEventListener('load', this.removePreloader);
    },
    methods: {
      removePreloader() {
        setTimeout(() => this.isLoaded = !this.isLoaded, 500);
      },
      // extendSesion (){
      //   const nect = parseInt(localStorage.getItem('NECC'));
      //   if ( nect == this.currentPeticion ){ // compare counter Globa and counter Vue

      //     // Try send 10 peticions to renew token
      //     if ( parseInt(localStorage.getItem('NECT')) == 8){
      //       clearTimeout(this.inactivyTimer);
      //       return ;
      //     }

      //     TokenService
      //     .getInstance()
      //     .getAccounts()
      //     .catch( () => {})
      //     .finally( () => {
      //       clearTimeout(this.inactivyTimer);
      //       this.inactivyTimer = setTimeout( this.extendSesion , 5 * 60 * 1000);
      //       localStorage.setItem( 'NECT', parseInt(localStorage.getItem('NECT')) + 1 );
      //     });
      //   } else {
      //     clearTimeout(this.inactivyTimer);
      //     this.inactivyTimer = setTimeout( this.extendSesion , 5 * 60 * 1000);
      //     this.currentPeticion = localStorage.getItem('NECC');
      //   }
      // }
    }
  };
</script>

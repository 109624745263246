import { EventObserver } from './event-observer';

export class CatchErrorObserver {

  static reject(headers) {
    EventObserver.$emit('rejecting', {
      headers,
      status: true
    });
  }
}

import { LoaderObserver } from '../../observer/loader-observer';
import { Storage } from '../../memento/storage';
import { CatchErrorObserver } from '../../observer/catch-error-observer';

const DEFAULT_NO_CORS = 'cors';
const DEFAULT_NO_CACHE = 'no-cache';
const DEFAULT_HTTP_METHOD = 'GET';
const DEFAULT_JOIN_DELIMITER = '/';
const DEFAULT_API_VERSION = PROFILE_BASE_URL + '/api/v1';
const DEFAULT_TIME_OUT_REQUEST = 150000;
const DEFAULT_HTTP_BAD_REQUEST_CODE = 400;
const DEFAULT_HTTP_UNAUTHORIZED_CODE = 401;
const DEFAULT_ACCEPT_HEADER_NAME = 'Accept';
const DEFAULT_ACCEPT_HEADER_VALUE = 'application/json';
const DEFAULT_CONTENT_TYPE_HEADER_NAME = 'Content-Type';
const DEFAULT_CONTENT_TYPE_HEADER_VALUE = 'application/json';
const MULTIPART_CONTENT_TYPE_HEADER_VALUE = 'multipart/form-data';
const FORM_DATA_CONTENT_TYPE_HEADER_VALUE = 'application/x-www-form-urlencoded';
const EXCEL_CONTENT_TYPE_HEADER_VALUE = 'application/vnd.ms-excel';
const DEFAULT_X_LANG_CODE_HEADER_NAME = 'X-Lang-Code';
const DEFAULT_X_LANG_CODE_HEADER_VALUE = 'ES';
const DEFAULT_ACCESS_TOKEN_HEADER_NAME = 'X-Access-Token';
const CONTENT_TYPES = [FORM_DATA_CONTENT_TYPE_HEADER_VALUE, MULTIPART_CONTENT_TYPE_HEADER_VALUE,
                       EXCEL_CONTENT_TYPE_HEADER_VALUE];
const DEFAULT_CONTENT_TYPE_HEADER_VALUES = ['application/pdf', 'image/jpeg', 'image/png',
                                            'image/jpg', 'application/vnd.ms-excel'];

export class ServiceFactory {

  apiVersion = DEFAULT_API_VERSION;

  buildFactory() {

    function requestFactory(body, options) {

      const {headers, uri, mode, cache, method} = options;

      if (!uri || !method) {
        return null;
      }

      const requestInit = {
        method,
        mode: mode || DEFAULT_NO_CORS,
        cache: cache || DEFAULT_NO_CACHE
      };

      const customHeaders = new Headers();

      if (headers && headers instanceof Array) {
        headers
          .forEach(header =>
            customHeaders.append(header.name, header.value));

        if (!headers.filter(header => header.name === DEFAULT_ACCEPT_HEADER_NAME).length) {
          customHeaders.append(DEFAULT_ACCEPT_HEADER_NAME, DEFAULT_ACCEPT_HEADER_VALUE);
        }

        if (!headers.filter(header => header.name === DEFAULT_CONTENT_TYPE_HEADER_NAME).length) {
          customHeaders.append(DEFAULT_CONTENT_TYPE_HEADER_NAME, DEFAULT_CONTENT_TYPE_HEADER_VALUE);
        }
      } else {
        customHeaders.append(DEFAULT_ACCEPT_HEADER_NAME, DEFAULT_ACCEPT_HEADER_VALUE);
        customHeaders.append(DEFAULT_CONTENT_TYPE_HEADER_NAME, DEFAULT_CONTENT_TYPE_HEADER_VALUE);
      }

      customHeaders.append(
        DEFAULT_X_LANG_CODE_HEADER_NAME,
        Storage.getItem('user') && Storage.getItem('user').lang ?
          Storage.getItem('user').lang :
          DEFAULT_X_LANG_CODE_HEADER_VALUE
      );

      customHeaders.append(DEFAULT_ACCESS_TOKEN_HEADER_NAME, Storage.getItem('accessToken'));

      requestInit.headers = customHeaders;

      if (method !== DEFAULT_HTTP_METHOD) {

        if (CONTENT_TYPES.includes(customHeaders.get(DEFAULT_CONTENT_TYPE_HEADER_NAME))) {

          const formData = new FormData();

          for (const name in body) {
            formData.append(name, body[name]);
          }

          requestInit.body = formData;
        } else if (DEFAULT_CONTENT_TYPE_HEADER_VALUE === customHeaders.get(DEFAULT_CONTENT_TYPE_HEADER_NAME)) {
          requestInit.body = JSON.stringify(body);
        } else {
          requestInit.body = body;
        }
      }

      return new Request(`${DEFAULT_API_VERSION}${uri}`, requestInit);
    }

    function serviceFactory(endpoints) {

      const service = {};

      function builder(options) {

        if (options && options instanceof Array) {
          service[options[0]] = function (data) {

            // if (!reset){
            //   const newValue = parseInt(localStorage.getItem('NECC')) + 1;
            //   localStorage.setItem( 'NECC', newValue ); // counter plus
            //   localStorage.setItem('NECT', 0); // times reset
            // }

            return Promise
              .race([
                new Promise((resolve, reject) => {

                  LoaderObserver.beforeStarting();

                  if (!(data && data.isNotLoading)) {
                    LoaderObserver.starting();
                  }

                  return fetch(requestFactory(data, mappingOptions(data, options[1])))
                    .then(response => {
                      if (response.status === DEFAULT_HTTP_UNAUTHORIZED_CODE) {
                        return location.replace(__ABOUT_WEB_APP_HOST__);
                      } else if (response.status >= DEFAULT_HTTP_BAD_REQUEST_CODE) {

                        Promise
                          .resolve(response.headers)
                          .then(headers => {

                            if (!(data && data.isNotLoading)) {
                              CatchErrorObserver.reject(headers);
                            }

                            return reject(response);
                          });
                      } else {

                        const contentType = response.headers.get(DEFAULT_CONTENT_TYPE_HEADER_NAME);

                        if (contentType && contentType.indexOf(DEFAULT_CONTENT_TYPE_HEADER_VALUE) !== -1) {

                          return Promise
                            .all([
                              Promise.resolve(response.status),
                              Promise.resolve(response.headers),
                              response
                                .json()
                                .then(json => Promise.resolve(json))
                                .catch(() => Promise.resolve({}))
                            ])
                            .then(replay => resolve({
                              status: replay[0],
                              headers: replay[1],
                              data: replay[2]
                            }));
                        } else if (contentType && DEFAULT_CONTENT_TYPE_HEADER_VALUES.includes(contentType)) {
                          return Promise
                            .all([
                              Promise.resolve(response.status),
                              Promise.resolve(response.headers),
                              response
                                .blob()
                                .then(blob => Promise.resolve(blob))
                                .catch(() => Promise.resolve({}))
                            ])
                            .then(replay => resolve({
                              status: replay[0],
                              headers: replay[1],
                              data: replay[2]
                            }));
                        } else {
                          return Promise
                            .all([
                              Promise.resolve(response.status),
                              Promise.resolve(response.headers),
                              response
                                .arrayBuffer()
                                .then(buffer => Promise.resolve(buffer))
                                .catch(() => Promise.resolve({}))
                            ])
                            .then(replay => resolve({
                              status: replay[0],
                              headers: replay[1],
                              data: replay[2]
                            }));
                        }
                      }
                    })
                    .catch(response =>
                      Promise
                        .resolve(response.headers)
                        .then(headers => {

                          if (!(data && data.isNotLoading)) {
                            CatchErrorObserver.reject(headers);
                          }

                          return reject(response);
                        })
                    )
                    .finally(() => LoaderObserver.stopping());
                }),
                new Promise((resolve, reject) =>
                  setTimeout(
                    reject,
                    DEFAULT_TIME_OUT_REQUEST,
                    `Timed out in ${DEFAULT_TIME_OUT_REQUEST} ms.`
                  )
                )
              ]);
          };
        }
      }

      if (endpoints && endpoints instanceof Object) {
        Object
          .entries(endpoints)
          .forEach(builder);
      }

      return service;
    }

    function mappingOptions(data, options) {

      const optionsDeque = {...options};

      if (data) {
        if (data.headers) {
          optionsDeque.headers = data.headers;

          delete data.headers;
        }

        if (data.path) {

          const paths = data.path.filter(p => ['number', 'string'].includes(typeof p));

          if (paths.length) {
            optionsDeque.uri = `${optionsDeque.uri}/${paths.join(DEFAULT_JOIN_DELIMITER)}`;
          }

          delete data.path;
        }

        if (data.params) {

          const queryParams = new URLSearchParams(data.params);

          optionsDeque.uri = `${optionsDeque.uri}?${decodeURIComponent(queryParams.toString())}`;

          delete data.params;
        }

        if (data.uri) {

          optionsDeque.uri = data.uri;

          delete data.uri;
        }

        if (data.method) {

          optionsDeque.method = data.method;

          delete data.method;
        }

        if (data.mode) {
          optionsDeque.mode = data.mode;

          delete data.mode;
        }

        if (data.cache) {
          optionsDeque.cache = data.cache;

          delete data.cache;
        }

        if (data.isNotLoading) {
          optionsDeque.isNotLoading = data.isNotLoading;
        }
      }

      return optionsDeque;
    }

    return serviceFactory;
  }
}

export class SupplierFlowCatalog {

  static #instance;

  #catalogs;

  constructor() {

    this.#catalogs = Object
      .freeze({
        SUPPLIER: {
          PLAN: {
            name: 'payment',
            path: 'payment',
            alias: 'PLAN'
          },
          PAYMENT: {
            name: 'payment',
            path: 'payment',
            alias: 'PAYMENT'
          },
          CHECKING_PAYMENT: {
            name: 'payment',
            path: 'payment',
            alias: 'CHECKING_PAYMENT'
          },
          PAYMENT_VALIDATED: {
            name: 'payment',
            path: 'payment',
            alias: 'PAYMENT_VALIDATED'
          },
          PAYMENT_CHANGE_PLAN: {
            name: 'payment',
            path: 'payment',
            alias: 'CHANGE_PLAN'
          },
          INFORMATION: {
            name: 'requestInformation',
            path: 'request-information',
            alias: 'INFORMATION'
          },
          CHECKING_INFORMATION: {
            name: 'validation',
            path: 'validation',
            alias: 'CHECKING_INFORMATION'
          },
          TERMINATION_REQUEST: {
            name: 'requestFinish',
            path: 'request-finish',
            alias: 'TERMINATION_REQUEST'
          },
          INFORMATION_VALIDATED: {
            name: 'validation',
            path: 'validation',
            alias: 'INFORMATION_VALIDATED'
          },
          DISCLAIMER: {
            name: 'disclaimer',
            path: 'disclaimer',
            alias: 'DISCLAIMER'
          },
          CHECKING_DISCLAIMER: {
            name: 'disclaimer',
            path: 'disclaimer',
            alias: 'CHECKING_DISCLAIMER'
          },
          DISCLAIMER_REJECTED: {
            name: 'disclaimer',
            path: 'disclaimer',
            alias: 'DISCLAIMER_REJECTED'
          },
          CERTIFIED: {
            name: 'certificate',
            path: 'certificate',
            alias: 'CERTIFIED'
          },
          UPDATE: {
            name: 'requestInformation',
            path: 'request-information',
            alias: 'UPDATE'
          },
          UPGRADE: {
            name: 'payment',
            path: 'payment',
            alias: 'UPGRADE'
          }
        },
        INITIAL: {
          INFORMATION: 'INFORMATION',
          VALIDATIONS: 'VALIDATION'
        },
        SECTIONS: {
          GENERAL_INFORMATION: {
            name: 'generalInformation',
            code: 'S1',
            path: 'general-information'
          },
          CONTACT_PERSON: {
            name: 'contactPerson',
            code: 'S2',
            path: 'contact-person'
          },
          PRODUCTS_AND_SERVICES: {
            name: 'productsAndServices',
            code: 'S3',
            path: 'products-and-services'
          },
          TEAM_MANAGEMENT: {
            name: 'teamManagement',
            code: 'S4',
            path: 'team-management'
          },
          FINANCIAL_INFORMATION: {
            name: 'financialInformation',
            code: 'S5',
            path: 'financial-information'
          },
          SYSTEM_MANAGEMENT: {
            name: 'systemManagement',
            code: 'S6',
            path: 'system-management'
          },
          ETHICS_AND_COMPLIANCE: {
            name: 'ethicsAndCompliance',
            code: 'S7',
            path: 'ethics-and-compliance'
          },
          INSURANCE_POLICY: {
            name: 'insurancePolicy',
            code: 'S8',
            path: 'insurance-policy'
          },
          QUESTIONNAIRE: {
            name: 'questionnaire',
            code: 'S9',
            path: 'questionnaire'
          },
          NEW_QUESTIONNAIRE: {
            name: 'questionnaire',
            code: 'S10',
            path: 'questionnaire'
          },
        },
        MISSION_COMPLETE_HEADER: 'X-Mission-Complete',
        CANCEL: 'CANCEL'
      });
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new SupplierFlowCatalog();
    }

    return this.#instance;
  }

  static getCatalogs() {
    return this.getInstance().#catalogs;
  }

  static getFlowPath(statusCode) {

    if (!statusCode) {
      return null;
    }

    return Object
      .values(this.getInstance().#catalogs.SUPPLIER)
      .filter(status => status.alias === statusCode)[0];
  }
}

<template src="./index.html"/>
<style
  src="./index.styl"
  lang="styl"
  scoped>
</style>
<script>
  import prvModal from '../../components/prv-modal-confirm/prv-modal-confirm.vue';

  import { AccountService } from '../../service/account-service';
  import { Storage } from '../../memento/storage';
  import { ACL } from '../../security/acl';
  import { Account } from '../../domain/account';

  import { ProfileFlowCatalog } from '../../views/profile/domain/profile-flow-catalog';
  import { SupplierFlowCatalog } from '../../domain/supplier-flow-catalog';

  export default {
    components: {
      'prv-modal': prvModal
    },
    beforeRouteEnter(to, from, next) {

      if (!Storage.getItem('user')) {
        location.replace(__SIGN_IN_WEB_APP_HOST__);
      }

      if (ACL.isSupplier(Storage.getItem('user').roleCode)) {

        AccountService
          .getInstance()
          .getAccount()
          .then(response => {
            Storage.setItem('account', new Account(response.data));

            next();
          })
          .catch(() => {});
      } else {
        next();
      }
    },
    data() {
      return {
        supplierFlow: SupplierFlowCatalog.getCatalogs(),
        account: Storage.getItem('account'),
        hasActivatedModal: true
      };
    },
    computed: {
      isNotPayment() {
        return this.account && !(this.account.statusCode === this.supplierFlow.SUPPLIER.PLAN.alias ||
               this.account.statusCode === this.supplierFlow.SUPPLIER.PAYMENT.alias ||
               this.account.statusCode === this.supplierFlow.SUPPLIER.CHECKING_PAYMENT.alias);
      }
    },
    created() {

      this.isSupplier = ACL.isSupplier(Storage.getItem('user').roleCode);

      if (this.isSupplier) {
        this.isAssociated = this.account.isAssociated;
      }

      const query = { ...this.$route.query };

      delete query.userId;
      delete query.accessToken;

      this.$router.replace({ query });
    },
    methods: {
      goToPrivacity() {
        this.$router.push({
          name: ProfileFlowCatalog.getCatalogs().PROFILE.PRIVACY.path
        });

        this.hasActivatedModal = false;
      }
    }
  };
</script>
import { ServiceFactory } from './factory/service-factory';
import { Storage } from '../memento/storage';

export class UserService extends ServiceFactory {

  static #instance;
  static #factory;

  constructor() {
    super();

    this.userId = Storage.getItem('userId');
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new UserService();
      this.#factory = this.#instance.#buildFactory();
    }

    return this.#factory;
  }

  #endpoints = () => {
    return {
      getUser: {
        method: 'GET',
        uri: `/users/${this.userId}`
      },
      getUsers: {
        method: 'GET',
        uri: '/users'
      },
      getNotifications: {
        method: 'GET',
        uri: `/users/${this.userId}/notifications`
      },
      setNotification: {
        method: 'PATCH',
        uri: `/users/${this.userId}/notifications`
      },
      createUser: {
        method: 'POST',
        uri: '/users'
      },
      setUser: {
        method: 'PATCH',
        uri: '/users'
      },
      getUserbyId:{
        method: 'GET',
        uri: '/users'
      }
    };
  };

  #buildFactory = () => super.buildFactory()(this.#endpoints());
}

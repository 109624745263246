import { UserService } from '../service/user-service';
import { AccountService } from '../service/account-service';
import { Storage } from '../memento/storage';
import { SupplierFlowCatalog } from '../domain/supplier-flow-catalog';
import { User } from '../domain/user';
import { Account } from '../domain/account';

export class ACL {

  static #roles = Object
    .freeze({
      STATUS: {
        ENABLE: 'ENABLE',
        DISABLE: 'DISABLE'
      },
      ROLES: {
        ACCT_USR: {
          name: 'ACCT_USR',
          path: 'supplier'
        },
        ACCT_OWNER: {
          name: 'ACCT_OWNER',
          path: 'supplier'
        },
        CONTROL_DESK: {
          name: 'CONTROL_DESK',
          path: 'validator'
        },
        BYR_BASIC: {
          name: 'BYR_BSC',
          path: 'buyer'
        },
        BYR_USR: {
          name: 'BYR_USR',
          path: 'buyer'
        },
        BYR_OWNER: {
          name: 'BYR_OWNER',
          path: 'buyer'
        },
        MANAGER: {
          name: 'MANAGER',
          path: 'manager'
        }
      },
      GRANTS: {
        PAYMENT: {
          name: 'GRANT_PAYMENT',
          path: 'payment'
        },
        INFORMATION: {
          name: 'GRANT_INFORMATION',
          path: 'information'
        },
        VISITS: {
          name: 'GRANT_VISITS_CHECK',
          path: 'visits'
        },
        MATCHES: {
          name: 'GRANT_MATCHES',
          path: 'compliance'
        },
        DISCLAIMER: {
          name: 'GRANT_DISCLAIMER',
          path: 'disclaimer'
        }
      }
    });

  static getRoles() {
    return ACL.#roles.ROLES;
  }

  static getRoleByCode(roleCode) {

    if (!roleCode) {
      return null;
    }

    return Object
      .values(ACL.#roles.ROLES)
      .filter(role => role.name === roleCode)[0];
  }

  static getRolePath(roleCode, statusCode) {

    const role = ACL.getRoleByCode(roleCode);

    if (ACL.isSupplier(roleCode) && statusCode) {

      const flowPath = SupplierFlowCatalog.getFlowPath(statusCode);
      return `${flowPath.path}`;
    } else {
      return `${role.path}`;
    }
  }

  static getDefaultRedirectPath() {

    const user = Storage.getItem('user');

    if (user) {

      const roleCode = user.roleCode;

      if (ACL.isSupplier(roleCode) && Storage.getItem('account')) {

        const statusCode = Storage.getItem('account').statusCode;

        return `${ACL.getRoles().ACCT_OWNER.path}/${ACL.getRolePath(roleCode, statusCode)}`;
      } else if (ACL.isApprover(roleCode)) {

        const grants = user.grant;

        return `${ACL.getRoles().CONTROL_DESK.path}/${ACL.getControlDeskPath(grants)}`;
      } else {
        return ACL.getRolePath(roleCode);
      }
    } else {
      return '';
    }
  }

  static getAuthorizedPath(to, next) {

    const queryParams = new URLSearchParams(location.hash.replace('#/', ''));

    const userId = queryParams.get('userId');
    const accessToken = queryParams.get('accessToken');

    const user = Storage.getItem('user');
    const userKey = Storage.getItem('userId');
    const currentToken = Storage.getItem('accessToken');

    if ((user && (!userId || !accessToken) && (userKey && currentToken)) ||
      (user && userId && userKey && userId === userKey && currentToken === accessToken)) {

      const { authorize } = to.meta;
      const roleCode = Storage.getItem('user').roleCode;

      if (authorize) {

        if (!roleCode) {
          return location.replace(__ABOUT_WEB_APP_HOST__);
        }

        if (authorize.length && !authorize.includes(roleCode)) {
          return next({ path: '/' });
        }

        if (ACL.isSupplier(roleCode) && Storage.getItem('account')) {

          const { statuses } = to.meta;
          const statusCode = Storage.getItem('account').statusCode;

          if (statuses) {

            if (!statusCode) {
              return next({ path: '/' });
            }

            if (!statuses.includes(statusCode)) {
              return next({ path: '/' });
            }
          } else {
            return next({ path: '/' });
          }
        }
      } else {
        return next({ path: '/' });
      }
    } else if (userId && accessToken) {

      Storage.clear();

      if (userId) {
        Storage.setItem('userId', userId);
      } else if (!Storage.getItem('userId') || Storage.getItem('userId') === 'undefined') {
        Storage.clear();
        location.replace(__ABOUT_WEB_APP_HOST__);
      }

      if (accessToken) {
        Storage.setItem('accessToken', accessToken);
      } else if (!Storage.getItem('accessToken') || Storage.getItem('accessToken') === 'undefined') {
        Storage.clear();
        location.replace(__ABOUT_WEB_APP_HOST__);
      }

      UserService
        .getInstance()
        .getUser()
        .then(response => {

          const roleCode = response.data.d4 && response.data.d4.d1;

          Storage.setItem('user', new User(response.data));

          if (ACL.isSupplier(roleCode)) {

            AccountService
              .getInstance()
              .getAccount()
              .then(response => {

                Storage.setItem('account', new Account(response.data));

                return next({
                  path: `${ACL.getRoles().ACCT_OWNER.path}/${ACL.getRolePath(
                    roleCode,
                    Storage.getItem('account').statusCode
                  )}`
                });
              })
              .catch(() => {
                Storage.clear();
                location.replace(__SIGN_IN_WEB_APP_HOST__);
              });
          } else {
            return next({ path: ACL.getRolePath(roleCode)});
          }
        })
        .catch(() => {
          Storage.clear();
          location.replace(__SIGN_IN_WEB_APP_HOST__);
        });
    } else if (!user || (!userId && !accessToken) || (!userKey && !currentToken)) {
      Storage.clear();
      location.replace(__ABOUT_WEB_APP_HOST__);
    }

    return next();
  }

  static isSupplier(roleCode) {
    return [
      ACL.#roles.ROLES.ACCT_OWNER.name,
      ACL.#roles.ROLES.ACCT_USR.name
    ].includes(roleCode);
  }

  static isApprover(roleCode) {
    return [ACL.#roles.ROLES.CONTROL_DESK.name].includes(roleCode);
  }

  static getControlDeskPath(grants) {

    if (!grants) {
      return null;
    }

    return Object
      .values(ACL.#roles.GRANTS)
      .find(role => grants
        .find(grant => grant.code === role.name))
        .path;
  }

  static isBuyer(roleCode) {
    return [
      ACL.#roles.ROLES.BYR_OWNER.name,
      ACL.#roles.ROLES.BYR_USR.name
    ].includes(roleCode);
  }

  static isBuyerOwner(roleCode) {
    return ACL.#roles.ROLES.BYR_OWNER.name === roleCode;
  }

  static isManager(roleCode) {
    return ACL.#roles.ROLES.MANAGER.name === roleCode;
  }
}

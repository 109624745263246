import { EventObserver } from './event-observer';

export class LoaderObserver {

  static #queue = 0;

  static beforeStarting() {
    this.#queue++;
  }

  static starting() {
    EventObserver.$emit('starting', true);
  }

  static stopping() {

    this.#queue--;

    if (this.#queue === 0) {
      EventObserver.$emit('stopping', false);
    }
  }
}

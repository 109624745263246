import { ServiceFactory } from './factory/service-factory';
import { Storage } from '../memento/storage';

export class AccountService extends ServiceFactory {

  static #instance;
  static #factory;
  static #services;

  constructor() {
    super();

    this.accountId = Storage.getItem('user').accountId;
  }

  static getInstance() {

    if (!this.#instance) {

      this.#instance = new AccountService();
      this.#services = this.#instance.#endpoints();
      this.#factory = this.#instance.#buildFactory();
    }

    return this.#factory;
  }

  #endpoints = () => {
    return {
      getAccounts: {
        method: 'GET',
        uri: '/accounts'
      },
      getAccount: {
        method: 'GET',
        uri: `/accounts/${this.accountId}`
      },
      downloadPayment: {
        method: 'GET',
        uri: '/accounts'
      },
      setAccount: {
        method: 'PATCH',
        uri: `/accounts/${this.accountId}`
      },
      setAccounts: {
        method: 'PATCH',
        uri: '/accounts'
      },
      setPayment: {
        method: 'PATCH',
        uri: '/accounts'
      }
    };
  };

  #buildFactory = () => super.buildFactory()(this.#endpoints());
}

export class Storage {

  static #instance;

  #storage;
  #customStorage = {};

  constructor() {
    if (localStorage) {
      this.#storage = localStorage;
    } else {
      this.#storage = {
        getItem(key) {
          return this.#customStorage[key];
        },
        removeItem(key) {
          delete this.#customStorage[key];
        },
        setItem(key, value) {
          if (key && value) {
            this.#customStorage[key] = value;
          }
        },
        clear() {
          this.#customStorage = {};
        }
      };
    }
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new Storage();
    }

    return this.#instance;
  }

  static getItem(key) {

    const result = this.getInstance().#storage.getItem(key);

    return result ? JSON.parse(result) : result;
  }

  static removeItem(key) {
    this.getInstance().#storage.removeItem(key);
  }

  static setItem(key, value) {
    this.getInstance().#storage.setItem(key, JSON.stringify(value));

    return this;
  }

  static clear() {
    this.getInstance().#storage.clear();
  }
}

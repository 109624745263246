export class ValidatorFlowCatalog {

  static #instance;

  #catalogs;

  constructor() {

    this.#catalogs = Object
      .freeze({
        VALIDATOR: {
          PAYMENTS: {
            name: 'payment',
            path: 'payment'
          },
          REQUEST_INFORMATION: {
            name: 'information',
            path: 'information'
          },
          DOCUMENTS: {
            name: 'documents',
            path: 'documents'
          },
          COMMUNICATION: {
            name: 'communication',
            path: 'communication'
          },
          DISCLAIMERS: {
            name: 'disclaimer',
            path: 'disclaimer'
          },
          VISITORS: {
            name: 'visits',
            path: 'visits'
          },
          REPORTS: {
            name: 'reports',
            path: 'reports'
          },
          MATCHES: {
            name: 'matches',
            path: 'matches'
          },
          CERTIFICATES: {
            name: 'certificates',
            path: 'certificates'
          },
          QUIZES: {
            name: 'quizes',
            path: 'quizes'
          }
        },
        GRANTS: {
          GRANT_PAYMENT: 'GRANT_PAYMENT',
          GRANT_INFORMATION: 'GRANT_INFORMATION',
          GRANT_VISITS_CHECK: 'GRANT_VISITS_CHECK',
          GRANT_MATCHES: 'GRANT_MATCHES',
          GRANT_DISCLAIMER: 'GRANT_DISCLAIMER'
        }
      });
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new ValidatorFlowCatalog();
    }

    return this.#instance;
  }

  static getCatalogs() {
    return this.getInstance().#catalogs;
  }
}

export class ManagerFlowCatalog {

  static #instance;

  #catalogs;

  constructor() {

    this.#catalogs = Object
      .freeze({
        MANAGER: {
          MANAGE_BUYER: {
            name: 'manageBuyer',
            path: 'manager'
          },
          BUYERS: {
            name: 'buyers',
            path: 'buyers'
          },
          GUESTS: {
            name: 'guests',
            path: 'guests'
          },
          MANAGE_USERS: {
            name: 'users',
            path: 'users'
          },
          MANAGE_ANALYTICS: {
            name: 'analyticsManager',
            path: 'analyticsManager'
          }
        }
      });
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new ManagerFlowCatalog();
    }

    return this.#instance;
  }

  static getCatalogs() {
    return this.getInstance().#catalogs;
  }

  static getFlowPath(statusCode) {

    if (!statusCode) {
      return null;
    }

    return Object
      .values(this.getInstance().#catalogs.MANAGER)
      .filter(status => status.alias === statusCode)[0];
  }
}

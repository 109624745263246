import Vue from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './vendors/vue-i18n/vue-i18n';
import { Vuelidate } from './vendors/vue-validate';
import './stylus/globals.styl';

const IS_PROD_PROFILE = (__PROFILE__ === 'prod');

Vue.config.devtools = !IS_PROD_PROFILE;
Vue.config.productionTip = IS_PROD_PROFILE;
Vue.config.debug = !IS_PROD_PROFILE;
Vue.config.silent = IS_PROD_PROFILE;

Vue.use(Vuelidate);

new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
});

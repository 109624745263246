export class BuyerFlowCatalog {

  static #instance;

  #catalogs;

  constructor() {

    this.#catalogs = Object
      .freeze({
        BUYER: {
          SEARCH: {
            name: 'search',
            path: 'search'
          },
          COMPANY: {
            name: 'company',
            path: 'company'
          },
          ANALYTICS: {
            name: 'analytics',
            path: 'analytics'
          },
          MULTISELECTION: {
            name: 'multiselection',
            path: 'multiselection'
          }
        }
      });
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new BuyerFlowCatalog();
    }

    return this.#instance;
  }

  static getCatalogs() {
    return this.getInstance().#catalogs;
  }
}

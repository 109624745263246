import { render, staticRenderFns } from "./index.html?vue&type=template&id=7cb437c2&scoped=true&"
import script from "./prv-modal-confirm.vue?vue&type=script&lang=js&"
export * from "./prv-modal-confirm.vue?vue&type=script&lang=js&"
import style0 from "./index.styl?vue&type=style&index=0&id=7cb437c2&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cb437c2",
  null
  
)

export default component.exports
export class ProfileFlowCatalog {

  static #instance;

  #catalogs;

  constructor() {

    this.#catalogs = Object
      .freeze({
        PROFILE: {
          MAIN: {
            name: 'profile',
            path: 'profile'
          },
          CONTACT: {
            name: 'contact',
            path: 'contact'
          },
          PASSWORD_RESET: {
            name: 'passwordReset',
            path: 'passwordReset'
          },
          PRIVACY: {
            name: 'privacy',
            path: 'privacy'
          }
        }
      });
  }

  static getInstance() {

    if (!this.#instance) {
      this.#instance = new ProfileFlowCatalog();
    }

    return this.#instance;
  }

  static getCatalogs() {
    return this.getInstance().#catalogs;
  }

  static getFlowPath(statusCode) {

    if (!statusCode) {
      return null;
    }

    return Object
      .values(this.getInstance().#catalogs.PROFILE)
      .filter(status => status.alias === statusCode)[0];
  }
}

import Vue from 'vue';
import Router from 'vue-router';
import home from '../views/home/home.vue';
import { ACL } from '../security/acl';
import { SupplierFlowCatalog } from '../domain/supplier-flow-catalog';
import { ValidatorFlowCatalog } from '../domain/validator-flow-catalog';
import { ManagerFlowCatalog } from '../views/manager/domain/manager-flow-catalog';
import { ProfileFlowCatalog } from '../views/profile/domain/profile-flow-catalog';
import { BuyerFlowCatalog } from '../domain/buyer-flow-catalog';

Vue.use(Router);

const supplier = resolve => {
  require.ensure(['../views/supplier/supplier.vue'],
    () => {
      resolve(require('../views/supplier/supplier.vue'));
    });
};

const payment = resolve => {
  require.ensure(['../views/supplier/payment/payment.vue'],
    () => {
      resolve(require('../views/supplier/payment/payment.vue'));
    },
    'Payment'
  );
};

const requestInformation = resolve => {
  require.ensure(['../views/supplier/request-information/request-information.vue'],
    () => {
      resolve(require('../views/supplier/request-information/request-information.vue'));
    },
    'Request-Information'
  );
};

const generalInformation = resolve => {
  require.ensure(['../components/prv-general-information/prv-general-information.vue'],
    () => {
      resolve(require('../components/prv-general-information/prv-general-information.vue'));
    },
    'Request-Information'
  );
};
const contactPerson = resolve => {
  require.ensure(['../components/prv-contact-person/prv-contact-person.vue'],
    () => {
      resolve(require('../components/prv-contact-person/prv-contact-person.vue'));
    },
    'Request-Information'
  );
};
const ethicsAndCompliance = resolve => {
  require.ensure([
      '../components/prv-ethics-and-compliance/ethics-and-compliance.vue'
    ],
    () => {
      resolve(require(
        '../components/prv-ethics-and-compliance/ethics-and-compliance.vue'
      ));
    },
    'Request-Information'
  );
};
const financialInformation = resolve => {
  require.ensure(['../components/prv-financial-information/prv-financial-information.vue'],
    () => {
      resolve(require('../components/prv-financial-information/prv-financial-information.vue'));
    },
    'Request-Information'
  );
};
const insurancePolicy = resolve => {
  require.ensure(['../components/prv-insurance-policy/prv-insurance-policy.vue'],
    () => {
      resolve(require('../components/prv-insurance-policy/prv-insurance-policy.vue'));
    },
    'Request-Information'
  );
};
const teamManagement = resolve => {
  require.ensure(['../components/prv-team-management/prv-team-management.vue'],
    () => {
      resolve(require('../components/prv-team-management/prv-team-management.vue'));
    },
    'Request-Information'
  );
};
const productsAndServices = resolve => {
  require.ensure(['../components/prv-products-and-services/prv-products-and-services.vue'],
    () => {
      resolve(require('../components/prv-products-and-services/prv-products-and-services.vue'));
    },
    'Request-Information'
  );
};
const systemManagement = resolve => {
  require.ensure(['../components/prv-system-management/prv-system-management.vue'],
    () => {
      resolve(require('../components/prv-system-management/prv-system-management.vue'));
    },
    'Request-Information'
  );
};

const questionnaire = resolve => {
  require.ensure(['../components/prv-questionnaire/prv-questionnaire.vue'],
    () => {
      resolve(require('../components/prv-questionnaire/prv-questionnaire.vue'));
    },
    'Request-Information'
  );
};

const requestFinish = resolve => {
  require.ensure(['../components/prv-request-finish/prv-request-finish.vue'],
    () => {
      resolve(require('../components/prv-request-finish/prv-request-finish.vue'));
    },
    'Request-Information'
  );
};

const disclaimer = resolve => {
  require.ensure(['../views/supplier/disclaimer/disclaimer.vue'],
    () => {
      resolve(require('../views/supplier/disclaimer/disclaimer.vue'));
    },
    'Declaration'
  );
};

const validation = resolve => {
  require.ensure(['../views/supplier/validation/validation.vue'],
    () => {
      resolve(require('../views/supplier/validation/validation.vue'));
    },
    'Validation'
  );
};

const certificate = resolve => {
  require.ensure(['../views/supplier/certificate/certificate.vue'],
    () => {
      resolve(require('../views/supplier/certificate/certificate.vue'));
    },
    'Certificate'
  );
};

const validator = resolve => {
  require.ensure(['../views/validator/validator.vue'],
    () => {
      resolve(require('../views/validator/validator.vue'));
    },
    'Validator'
  );
};

const payments = resolve => {
  require.ensure(['../views/validator/payments/payments.vue'],
    () => {
      resolve(require('../views/validator/payments/payments.vue'));
    },
    'Validator'
  );
};

const behest = resolve => {
  require.ensure(['../views/validator/request-information/request-information.vue'],
    () => {
      resolve(require('../views/validator/request-information/request-information.vue'));
    },
    'Validator'
  );
};

const disclaimers = resolve => {
  require.ensure(['../views/validator/disclaimers/disclaimers.vue'],
    () => {
      resolve(require('../views/validator/disclaimers/disclaimers.vue'));
    },
    'Validator'
  );
};

const visitors = resolve => {
  require.ensure(
    ['../views/validator/visitors/visitors.vue'],
    () => {
      resolve(require('../views/validator/visitors/visitors.vue'));
    },
    'Validator'
  );
};

const reports = resolve => {
  require.ensure(
    ['../views/validator/reports/reports.vue'],
    () => {
      resolve(require('../views/validator/reports/reports.vue'));
    },
    'Validator'
  );
};

const matches = resolve => {
  require.ensure(
    ['../views/validator/matches/matches.vue'],
    () => {
      resolve(require('../views/validator/matches/matches.vue'));
    },
    'Validator'
  );
};

const quizes = resolve => {
  require.ensure(
    ['../views/validator/quizes/quiz.vue'],
    () => {
      resolve(require('../views/validator/quizes/quiz.vue'));
    },
    'Validator'
  );
};

const certificates = resolve => {
  require.ensure(
    ['../views/validator/certificates/certificates.vue'],
    () => {
      resolve(require('../views/validator/certificates/certificates.vue'));
    },
    'Validator'
  );
};

const buyer = resolve => {
  require.ensure(['../views/buyer/buyer.vue'],
    () => {
      resolve(require('../views/buyer/buyer.vue'));
    },
    'Buyer'
  );
};

const supplierSearch = resolve => {
  require.ensure(['../components/prv-supplier-search/prv-supplier-search.vue'],
    () => {
      resolve(require('../components/prv-supplier-search/prv-supplier-search.vue'));
    },
    'Buyer'
  );
};

const manager = resolve => {
  require.ensure(['../views/manager/manager.vue'],
    () => {
      resolve(require('../views/manager/manager.vue'));
    },
    'Manager'
  );
};

const buyerCompany = resolve => {
  require.ensure(['../components/prv-buyer-company/prv-buyer-company.vue'],
    () => {
      resolve(require('../components/prv-buyer-company/prv-buyer-company.vue'));
    }
  );
};

const guests = resolve => {
  require.ensure(['../views/manager/guests/guests.vue'],
    () => {
      resolve(require('../views/manager/guests/guests.vue'));
    },
    'Manager'
  );
};

const users = resolve => {
  require.ensure(['../views/manager/users/users.vue'],
    () => {
      resolve(require('../views/manager/users/users.vue'));
    },
    'Manager'
  );
};

const analytics = resolve => {
  require.ensure(['../components/prv-analytics/prv-analytics.vue'],
    () => {
      resolve(require('../components/prv-analytics/prv-analytics.vue'));
    },
    'Manager'
  );
};

const multiselection = resolve => {
  require.ensure(['../components/prv-multi-selection/prv-multi-selection.vue'],
    () => {
      resolve(require('../components/prv-multi-selection/prv-multi-selection.vue'));
    },
    'Profile'
  );
};

const profile = resolve => {
  require.ensure(['../views/profile/profile.vue'],
    () => {
      resolve(require('../views/profile/profile.vue'));
    },
    'Profile'
  );
};

const contact = resolve => {
  require.ensure(['../views/profile/contact/contact.vue'],
    () => {
      resolve(require('../views/profile/contact/contact.vue'));
    },
    'Profile'
  );
};

const resetPassword = resolve => {
  require.ensure(['../views/profile/resetPassword/resetPassword.vue'],
    () => {
      resolve(require('../views/profile/resetPassword/resetPassword.vue'));
    },
    'Profile'
  );
};

const privacy = resolve => {
  require.ensure(['../views/profile/privacy/privacy.vue'],
    () => {
      resolve(require('../views/profile/privacy/privacy.vue'));
    },
    'Profile'
  );
};

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      authorize: [
        ACL.getRoles().ACCT_USR.name,
        ACL.getRoles().ACCT_OWNER.name,
        ACL.getRoles().BYR_USR.name,
        ACL.getRoles().BYR_OWNER.name,
        ACL.getRoles().BYR_BASIC.name,
        ACL.getRoles().CONTROL_DESK.name,
        ACL.getRoles().MANAGER.name
      ]
    },
    redirect: () => ACL.getDefaultRedirectPath(),
    children: [
      {
        path: ACL.getRoles().ACCT_OWNER.path,
        name: ACL.getRoles().ACCT_OWNER.name,
        component: supplier,
        meta: {
          authorize: [
            ACL.getRoles().ACCT_OWNER.name,
            ACL.getRoles().ACCT_USR.name
          ]
        },
        children: [
          {
            path: SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT.path,
            name: SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT.name,
            component: payment,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PLAN.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_PAYMENT.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT_VALIDATED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().ACCT_USR.name
              ]
            }
          },
          {
            path: SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.path,
            name: SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.name,
            component: requestInformation,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().ACCT_USR.name
              ]
            },
            children: [
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.GENERAL_INFORMATION.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.GENERAL_INFORMATION.name,
                component: generalInformation,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.CONTACT_PERSON.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.CONTACT_PERSON.name,
                component: contactPerson,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.ETHICS_AND_COMPLIANCE.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.ETHICS_AND_COMPLIANCE.name,
                component: ethicsAndCompliance,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.FINANCIAL_INFORMATION.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.FINANCIAL_INFORMATION.name,
                component: financialInformation,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.INSURANCE_POLICY.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.INSURANCE_POLICY.name,
                component: insurancePolicy,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.TEAM_MANAGEMENT.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.TEAM_MANAGEMENT.name,
                component: teamManagement,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.PRODUCTS_AND_SERVICES.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.PRODUCTS_AND_SERVICES.name,
                component: productsAndServices,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.FINANCIAL_INFORMATION.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.FINANCIAL_INFORMATION.name,
                component: financialInformation,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.SYSTEM_MANAGEMENT.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.SYSTEM_MANAGEMENT.name,
                component: systemManagement,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SECTIONS.QUESTIONNAIRE.path,
                name: SupplierFlowCatalog.getCatalogs().SECTIONS.QUESTIONNAIRE.name,
                component: questionnaire,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              },
              {
                path: SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.path,
                name: SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.name,
                component: requestFinish,
                meta: {
                  statuses: [
                    SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias
                  ],
                  authorize: [
                    ACL.getRoles().ACCT_OWNER.name,
                    ACL.getRoles().ACCT_USR.name
                  ]
                }
              }
            ]
          },
          {
            path: SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.path,
            name: SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.name,
            component: validation,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION_VALIDATED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().ACCT_USR.name
              ]
            }
          },
          {
            path: SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.path,
            name: SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.name,
            component: disclaimer,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER_REJECTED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().ACCT_USR.name
              ]
            }
          },
          {
            path: SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.path,
            name: SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.name,
            component: certificate,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().ACCT_USR.name
              ]
            }
          }
        ]
      },
      {
        path: ACL.getRoles().CONTROL_DESK.path,
        name: ACL.getRoles().CONTROL_DESK.name,
        component: validator,
        meta: {
          authorize: [ACL.getRoles().CONTROL_DESK.name]
        },
        redirect: {
          name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.PAYMENTS.name
        },
        children: [
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.PAYMENTS.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.PAYMENTS.name,
            component: payments,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.REQUEST_INFORMATION.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.REQUEST_INFORMATION.name,
            component: behest,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.DISCLAIMERS.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.DISCLAIMERS.name,
            component: disclaimers,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.VISITORS.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.VISITORS.name,
            component: visitors,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.REPORTS.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.REPORTS.name,
            component: reports,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.MATCHES.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.MATCHES.name,
            component: matches,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.CERTIFICATES.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.CERTIFICATES.name,
            component: certificates,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ValidatorFlowCatalog.getCatalogs().VALIDATOR.QUIZES.path,
            name: ValidatorFlowCatalog.getCatalogs().VALIDATOR.QUIZES.name,
            component: quizes,
            meta: {
              authorize: [
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
        ]
      },
      {
        path: ACL.getRoles().BYR_USR.path,
        name: ACL.getRoles().BYR_USR.name,
        component: buyer,
        meta: {
          authorize: [
            ACL.getRoles().BYR_USR.name,
            ACL.getRoles().BYR_BASIC.name,
            ACL.getRoles().BYR_OWNER.name,
          ]
        },
        redirect: {
          name: BuyerFlowCatalog.getCatalogs().BUYER.SEARCH.name
        },
        children: [
          {
            path: BuyerFlowCatalog.getCatalogs().BUYER.SEARCH.path,
            name: BuyerFlowCatalog.getCatalogs().BUYER.SEARCH.name,
            component: supplierSearch,
            meta: {
              authorize: [
                ACL.getRoles().BYR_USR.name,
                ACL.getRoles().BYR_OWNER.name,
                ACL.getRoles().BYR_BASIC.name,
              ]
            }
          },
          {
            path: BuyerFlowCatalog.getCatalogs().BUYER.COMPANY.path,
            name: BuyerFlowCatalog.getCatalogs().BUYER.COMPANY.name,
            component: buyerCompany,
            meta: {
              authorize: [
                ACL.getRoles().BYR_OWNER.name
              ]
            }
          },
          {
            path: BuyerFlowCatalog.getCatalogs().BUYER.ANALYTICS.path,
            name: BuyerFlowCatalog.getCatalogs().BUYER.ANALYTICS.name,
            component: analytics,
            props: {
              url: __ANALYTICS_URL__
            },
            meta: {
              authorize: [
                ACL.getRoles().BYR_OWNER.name
              ]
            }
          },
          {
            path: BuyerFlowCatalog.getCatalogs().BUYER.MULTISELECTION.path,
            name: BuyerFlowCatalog.getCatalogs().BUYER.MULTISELECTION.name,
            component: multiselection,
            meta: {
              authorize: [
                ACL.getRoles().BYR_USR.name,
                ACL.getRoles().BYR_OWNER.name
              ]
            }
          },
        ],
      },
      {
        path: ACL.getRoles().MANAGER.path,
        name: ACL.getRoles().MANAGER.name,
        component: manager,
        meta: {
          authorize: [
            ACL.getRoles().MANAGER.name
          ]
        },
        redirect: {
          name: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_BUYER.name
        },
        children: [
          {
            path: ManagerFlowCatalog.getCatalogs().MANAGER.BUYERS.path,
            name: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_BUYER.name,
            component: buyerCompany,
            meta: {
              authorize: [
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ManagerFlowCatalog.getCatalogs().MANAGER.GUESTS.path,
            name: ManagerFlowCatalog.getCatalogs().MANAGER.GUESTS.name,
            component: guests,
            meta: {
              authorize: [
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_USERS.path,
            name: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_USERS.name,
            component: users,
            meta: {
              authorize: [
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_ANALYTICS.path,
            name: ManagerFlowCatalog.getCatalogs().MANAGER.MANAGE_ANALYTICS.name,
            component: analytics,
            props: {
              url: __ANALYTICS_URL__
            },
            meta: {
              authorize: [
                ACL.getRoles().MANAGER.name
              ]
            }
          },
        ]
      },
      {
        path: ProfileFlowCatalog.getCatalogs().PROFILE.MAIN.path,
        name: ProfileFlowCatalog.getCatalogs().PROFILE.MAIN.name,
        component: profile,
        meta: {
          statuses: [
            SupplierFlowCatalog.getCatalogs().SUPPLIER.PLAN.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_PAYMENT.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT_VALIDATED.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION_VALIDATED.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_DISCLAIMER.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER_REJECTED.alias,
            SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.alias
          ],
          authorize: [
            ACL.getRoles().ACCT_USR.name,
            ACL.getRoles().ACCT_OWNER.name,
            ACL.getRoles().BYR_USR.name,
            ACL.getRoles().BYR_OWNER.name,
            ACL.getRoles().CONTROL_DESK.name,
            ACL.getRoles().MANAGER.name
          ]
        },
        redirect: {
          name: ProfileFlowCatalog.getCatalogs().PROFILE.CONTACT.name
        },
        children: [
          {
            path: ProfileFlowCatalog.getCatalogs().PROFILE.PASSWORD_RESET.path,
            name: ProfileFlowCatalog.getCatalogs().PROFILE.PASSWORD_RESET.name,
            component: resetPassword,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PLAN.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_PAYMENT.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER_REJECTED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_USR.name,
                ACL.getRoles().ACCT_OWNER.name,
                ACL.getRoles().BYR_USR.name,
                ACL.getRoles().BYR_OWNER.name,
                ACL.getRoles().CONTROL_DESK.name,
                ACL.getRoles().MANAGER.name
              ]
            }
          },
          {
            path: ProfileFlowCatalog.getCatalogs().PROFILE.CONTACT.path,
            name: ProfileFlowCatalog.getCatalogs().PROFILE.CONTACT.name,
            component: contact,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER_REJECTED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_USR.name,
                ACL.getRoles().ACCT_OWNER.name
              ]
            }
          },
          {
            path: ProfileFlowCatalog.getCatalogs().PROFILE.PRIVACY.path,
            name: ProfileFlowCatalog.getCatalogs().PROFILE.PRIVACY.name,
            component: privacy,
            meta: {
              statuses: [
                SupplierFlowCatalog.getCatalogs().SUPPLIER.PAYMENT_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_INFORMATION.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.TERMINATION_REQUEST.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.INFORMATION_VALIDATED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CHECKING_DISCLAIMER.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.DISCLAIMER_REJECTED.alias,
                SupplierFlowCatalog.getCatalogs().SUPPLIER.CERTIFIED.alias
              ],
              authorize: [
                ACL.getRoles().ACCT_USR.name,
                ACL.getRoles().ACCT_OWNER.name
              ]
            }
          }
        ]
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new Router({
  routes
});

router.beforeEach((to, from, next) => ACL.getAuthorizedPath(to, next));

export default router;
